import Container from '@mui/material/Container';
import Gateway from './Gateway';

const App = () => {
  return (
    <Container maxWidth='md'>
      <Gateway />
    </Container>
  );
};

export default App;
